import { Divider, Grid, Stack, Typography } from '@mui/material';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { listAncillaryBenefits } from 'actions/teamBenefit/listAncillaryBenefits';
import { getHousehold } from 'actions/user/getHousehold';
import { getHouseholdEligibility } from 'actions/user/getHouseholdEligibility';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import {
    EnrollmentStatuses,
    PlanListing,
    PlanTypeIds,
    Tasks,
    UserTeamBenefitTermDetailStatuses,
} from 'api/generated/enums';
import {
    IAncillaryBenefitDto,
    IMarketplacePlanDto,
    IPlanInfoDto,
    ISelectedPlansDto,
    MajorMedicalBenefitCostDto,
    PlanInfoDto,
    TaskDto,
} from 'api/generated/models';
import Skeleton from 'components/Skeleton';
import { RH_TEAM_CARE_EMAIL } from 'constants/teams';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import {
    IIchraEnrollmentTaskFlowContent,
    MAX_WIDTH,
    convertToMarketplacePlan,
    convertToPlanInfo,
    getPreviousTask,
    getSupportedEnrollmentUserFlow,
    handleAncillaryValues,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import IchraFlowButtons from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowButtons';
import IchraFlowTitle from 'pages/dashboard/ichraTaskFlow/taskFlowPages/components/IchraFlowTitle';
import AcceptedAncillary from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/AcceptedAncillary';
import MajorMedicalCostBreakdown from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/MajorMedicalCostBreakdown';
import UnacceptedAncillaries from 'pages/dashboard/ichraTaskFlow/taskFlowPages/submitBenefitsSelectionPage/UnacceptedAncillary';
import MarketplacePlanCard from 'pages/shop/MarketplacePlanCard';
import PlanCardHeader from 'pages/shop/PlanCardHeader';
import { IShoppingPlan } from 'pages/shop/shopping';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { DASHBOARD_PATH } from 'routers/routes';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

type IOldPlanInfoDto = {
    householdMembersCovered: string[];
    isPrimaryCovered: boolean;
    plan: IMarketplacePlanDto;
    planId: string;
    planTypeId: PlanListing;
};

const SubmitBenefitSelectionPage = ({ currentTask }: IIchraEnrollmentTaskFlowContent) => {
    const dispatch = useThunkDispatch();
    const { activeWageUp, isCurrent, user, userId, yearlyUserInfo } = useUserProps();
    const { ancillariesFromState, flowHasErrors, isLoading, userFlows } = useSelector(
        (state: AppStore) => ({
            ancillariesFromState: state.ancillaryBenefits,
            flowHasErrors: state.ichraFlowState.hasErrors,
            isLoading: hasApiActivity(state, LIST_VISIBLE_FLOWS_ACTION, GET_USER_PROFILE_ACTION),
            userFlows: state.userFlows,
        })
    );
    const [acceptedAncillaries, setAcceptedAncillaries] = useState<IAncillaryBenefitDto[]>();
    const [unacceptedAncillaries, setUnacceptedAncillaries] = useState<IAncillaryBenefitDto[]>();
    const [selectedPlans, setSelectedPlans] = useState<ISelectedPlansDto>();
    const [shouldSaveChooseYourPlanTask, setShouldSaveChooseYourPlanTask] = useState<boolean>(
        false
    );

    const userFlow = getSupportedEnrollmentUserFlow(userFlows);
    const ichraTasks = userFlow?.tasks as TaskDto[];
    const chooseYourPlanTask = getPreviousTask(
        ichraTasks,
        Tasks.ChooseYourPlan,
        Tasks.SelectBenefits
    );
    const isWaived = selectedPlans?.isWaived;
    const ancillaryBenefitTask = getPreviousTask(
        ichraTasks,
        Tasks.SelectAncillaryBenefits,
        Tasks.SelectBenefits
    );
    const toastSuccessMessage = 'Benefits selections submitted!';
    const toastErrorMessage = 'Failed to submit benefits selections';

    const getIsIndividualOnly = (plan: IPlanInfoDto) =>
        plan?.householdMembersCovered?.length === (plan?.isPrimaryCovered ? 0 : 1);

    const handleSubmit = async () => {
        if (shouldSaveChooseYourPlanTask) {
            const shoppingTask = getPreviousTask(
                ichraTasks,
                Tasks.ShopForPlans,
                Tasks.ChooseYourPlan,
                Tasks.SelectBenefits
            );
            await dispatch(completeTask(shoppingTask?.globalId as string, selectedPlans));
        }

        dispatch(
            completeTask(
                currentTask.globalId,
                undefined,
                DASHBOARD_PATH,
                toastErrorMessage,
                toastSuccessMessage
            )
        );
    };

    const activeDateYear = user?.activeDate?.getYear();
    const isAdvisementScheduled =
        yearlyUserInfo?.enrollmentStatus?.value === EnrollmentStatuses.AdvisementScheduled;

    let totalPlanCost = 0;
    selectedPlans?.planInfoDtos?.forEach((p) => (totalPlanCost += p.planPremium ?? 0));
    const activeReimbursementAmount = activeWageUp?.reimbursement ?? 0;

    const getHeader = (plan: IPlanInfoDto) => (
        <PlanCardHeader
            carrier={plan?.issuerName}
            cost={plan?.planPremiumWithCredits}
            hidePlanCost
            hidePreviouslySelected
            hideYourCost
            isChunkPlan
            isFlowSummary
            name={plan?.planName}
            plan={convertToMarketplacePlan(plan)}
            reimbursementAmount={activeReimbursementAmount}
            showTaxSavings={
                [PlanTypeIds.Marketplace, PlanTypeIds.OffExchange].contains(plan?.planTypeId) &&
                !hasValue(plan?.exchange)
            }
        />
    );

    useEffect(
        () => {
            const chunk = chooseYourPlanTask?.response as ISelectedPlansDto;
            const shouldSave = hasValue(
                ((chunk?.planInfoDtos?.[0] as unknown) as IOldPlanInfoDto)?.plan
            );
            setShouldSaveChooseYourPlanTask(shouldSave);

            if (shouldSave) {
                const newChunkPlans = chunk?.planInfoDtos?.map(
                    (p) =>
                        convertToPlanInfo(
                            p.householdMembersCovered,
                            p.isPrimaryCovered,
                            ((p as unknown) as IOldPlanInfoDto).plan,
                            undefined,
                            userFlow?.year
                        ) as PlanInfoDto
                );
                setSelectedPlans({ isWaived: chunk.isWaived, planInfoDtos: newChunkPlans });
            } else {
                setSelectedPlans(chunk);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        dispatch(listAncillaryBenefits(userId, true));
    }, [dispatch, userId]);

    useEffect(() => {
        dispatch(getHousehold(userId, isCurrent));
    }, [dispatch, isCurrent, userId]);

    useEffect(() => {
        if (hasValue(user?.activeDate)) {
            dispatch(getHouseholdEligibility(userId, user?.activeDate.getYear() as number));
        }
    }, [dispatch, user?.activeDate, userId]);

    useEffect(() => {
        const updatedAncillaries = handleAncillaryValues(
            ancillaryBenefitTask as TaskDto,
            ancillariesFromState,
            userId
        );
        const desiredAncillaries = updatedAncillaries.filter((da) =>
            [
                UserTeamBenefitTermDetailStatuses.Enrolled,
                UserTeamBenefitTermDetailStatuses.Submitted,
            ].contains(da.statusId)
        );
        const acceptedAncillaryTypes = desiredAncillaries.map((da) => da.teamBenefitTypeId);
        const undesiredAncillaries = updatedAncillaries.filter(
            (ua) =>
                ua.statusId === UserTeamBenefitTermDetailStatuses.Waived &&
                !acceptedAncillaryTypes.contains(ua.teamBenefitTypeId)
        );
        setAcceptedAncillaries(desiredAncillaries);
        setUnacceptedAncillaries(undesiredAncillaries);
    }, [ancillariesFromState, ancillaryBenefitTask, userId]);

    return (
        <Stack
            direction="column"
            maxWidth={{ lg: MAX_WIDTH, xs: '100%' }}
            minWidth={{ lg: '650px', xs: '100%' }}
            width="100%"
        >
            <IchraFlowTitle title="Submit Benefits Selections" />
            <Skeleton count={1} height={250} isEnabled={isLoading} width="100%">
                {!flowHasErrors ? (
                    <Grid container direction="column" item marginTop={2} rowSpacing={2} xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h4">ICHRA Major Medical Enrollment</Typography>
                        </Grid>
                        <Grid item>
                            {isWaived ? (
                                <Typography
                                    fontStyle="italic"
                                    fontWeight="400"
                                    marginBottom={4}
                                    variant="h4"
                                >
                                    Waiving ICHRA for {activeDateYear}
                                </Typography>
                            ) : (
                                <Grid
                                    container
                                    direction="column"
                                    gap={3}
                                    item
                                    marginBottom={2}
                                    width="100%"
                                >
                                    {selectedPlans?.planInfoDtos?.map((plan) =>
                                        [PlanTypeIds.Marketplace, PlanTypeIds.OffExchange].contains(
                                            plan.planTypeId
                                        ) ? (
                                            <MarketplacePlanCard
                                                hideCost
                                                hideSelectPlan
                                                householdMembersCovered={
                                                    plan?.householdMembersCovered
                                                }
                                                individualOnly={getIsIndividualOnly(plan)}
                                                isPrimaryCovered={plan?.isPrimaryCovered}
                                                key={`${plan?.planId}-${plan?.planTypeId}`}
                                                pathwayPlanCardHeader={getHeader(plan)}
                                                plan={
                                                    {
                                                        ...convertToMarketplacePlan(plan),
                                                        isMarketplace: true,
                                                    } as IShoppingPlan
                                                }
                                            />
                                        ) : (
                                            <MarketplacePlanCard
                                                hideCost
                                                hidePlanCardContent
                                                hideSelectPlan
                                                householdMembersCovered={
                                                    plan?.householdMembersCovered
                                                }
                                                individualOnly={getIsIndividualOnly(plan)}
                                                isPrimaryCovered={plan?.isPrimaryCovered}
                                                key={`${plan?.planId}-${plan?.planTypeId}`}
                                                pathwayPlanCardHeader={getHeader(plan)}
                                                plan={
                                                    {
                                                        ...(plan as Partial<IMarketplacePlanDto>),
                                                        isMarketplace: true,
                                                    } as IShoppingPlan
                                                }
                                            />
                                        )
                                    )}

                                    <MajorMedicalCostBreakdown
                                        majorMedicalBenefitCost={
                                            {
                                                ichraMajorMedicalBenefitCost:
                                                    totalPlanCost - activeReimbursementAmount,
                                                isIchra: true,
                                                postTaxTotalPlanCost: totalPlanCost,
                                                reimbursementAmount: activeReimbursementAmount,
                                            } as MajorMedicalBenefitCostDto
                                        }
                                        plans={selectedPlans?.planInfoDtos}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        {arrayHasValue(ancillariesFromState) && (
                            <Grid container item rowSpacing={2} xs={12}>
                                <Grid item xs={12}>
                                    <Divider sx={{ marginBottom: 3 }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h4">Ancillary Benefits</Typography>
                                </Grid>
                                <Grid columnSpacing={4} container item xs={12}>
                                    {acceptedAncillaries?.map((ancillary) => (
                                        <AcceptedAncillary
                                            ancillary={ancillary}
                                            key={ancillary.teamBenefitTypeId}
                                        />
                                    ))}
                                    {unacceptedAncillaries?.map((ancillary) => (
                                        <UnacceptedAncillaries
                                            ancillary={ancillary}
                                            key={ancillary.teamBenefitTypeId}
                                        />
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Typography marginX="auto">
                            There was an error getting your ICHRA offer details.
                        </Typography>
                        <Typography marginX="auto">
                            To resolve this, please reach out to{' '}
                            <a href={`mailto:${RH_TEAM_CARE_EMAIL}`}>{RH_TEAM_CARE_EMAIL}</a>.
                        </Typography>
                    </React.Fragment>
                )}
            </Skeleton>
            {isAdvisementScheduled && (
                <Typography marginTop={2} marginX="auto" variant="body2">
                    You have an advisement scheduled, please wait to review your selections with
                    your advisor.
                </Typography>
            )}
            <IchraFlowButtons
                currentTaskId={currentTask.globalId}
                forwardButtonTextOverride="Submit"
                handleNext={handleSubmit}
                isDisabled={isAdvisementScheduled}
                isLoading={isLoading}
                showPreviousButton
            />
        </Stack>
    );
};

export default hot(module)(SubmitBenefitSelectionPage);
