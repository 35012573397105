import { Grid } from '@mui/material';
import { PlanListing } from 'api/generated/enums';
import { IMarketplacePlanDto, PathwayRecommendedPlan } from 'api/generated/models';
import RecommendedPlanItem from 'pages/dashboard/ichraTaskFlow/taskFlowPages/recommendedBenefitsPathPage/RecommendedPlanItem';
import React, { createContext, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';

export type IRecommendedPlanContext = {
    detailsExpanded: boolean;
    setDetailsExpanded?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RecommendedPlanContext = createContext<IRecommendedPlanContext>({
    detailsExpanded: false,
});

const MAX_NUMBER_OF_PLANS = 3;
const SMALL = 4;
const MEDIUM = 6;

const RecommendedPlansContent = ({
    handleSubmit,
    plansFromMarketplace,
    recommendedPlans,
}: {
    handleSubmit: (plan?: IMarketplacePlanDto) => Promise<void>;
    plansFromMarketplace: IMarketplacePlanDto[];
    recommendedPlans: PathwayRecommendedPlan[];
}) => {
    const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);
    const planSize = recommendedPlans?.length === MAX_NUMBER_OF_PLANS ? SMALL : MEDIUM;
    const getMarketplacePlan = (planId?: string) =>
        plansFromMarketplace.find((mp) => mp.id === planId);
    const showTaxSavings =
        recommendedPlans.some((rp) => rp.benchmarkType === PlanListing.OffExchange) &&
        recommendedPlans.some((rp) => rp.benchmarkType === PlanListing.OnExchange);

    const contextValue = useMemo(() => ({ detailsExpanded, setDetailsExpanded }), [
        detailsExpanded,
    ]);

    return (
        <RecommendedPlanContext.Provider value={contextValue}>
            {recommendedPlans?.map((rp) => (
                <Grid
                    alignItems="space-between"
                    container
                    direction="row"
                    item
                    justifyContent="center"
                    key={rp.globalId}
                    sm={planSize}
                    xs={12}
                >
                    <RecommendedPlanItem
                        handleSubmit={handleSubmit}
                        marketplacePlan={getMarketplacePlan(rp.planId)}
                        plan={rp}
                        showTaxSavings={showTaxSavings}
                    />
                </Grid>
            ))}
        </RecommendedPlanContext.Provider>
    );
};

export default hot(module)(RecommendedPlansContent);
