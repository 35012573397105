import { Divider, Grid, Typography } from '@mui/material';
import { MajorMedicalBenefitCostDto, PlanInfoDto } from 'api/generated/models';
import DesktopContext from 'contexts/DesktopContext';
import React, { useContext } from 'react';
import { hot } from 'react-hot-loader';
import { formatCurrency } from 'utilities/format';
import { hasValue } from 'utilities/index';

type IMajorMedicalCostBreakdown = {
    majorMedicalBenefitCost?: MajorMedicalBenefitCostDto;
    plans?: PlanInfoDto[];
};

const MajorMedicalCostBreakdown = ({
    majorMedicalBenefitCost,
    plans,
}: IMajorMedicalCostBreakdown) => {
    const isDesktop = useContext(DesktopContext);
    const fontVariant = isDesktop ? 'body1' : 'body2';

    const formattedYourCost =
        (majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost as number) > 0
            ? majorMedicalBenefitCost?.ichraMajorMedicalBenefitCost
            : 0;

    return (
        <Grid container gap={1} item marginX="auto" minWidth={325} width="67%">
            <Grid container direction="row" justifyContent="space-between" width="100%">
                <Typography marginLeft={1.3} variant={fontVariant}>
                    {hasValue(plans) && plans?.length > 1 ? 'Total Plan Costs:' : 'Plan Cost'}
                </Typography>
                <Typography variant={fontVariant}>
                    {`${formatCurrency(majorMedicalBenefitCost?.postTaxTotalPlanCost as number, {
                        preserveDecimal: true,
                    })} per month`}
                </Typography>
            </Grid>
            <Grid container direction="row" justifyContent="space-between" width="100%">
                <Typography variant={fontVariant}>- Maximum Reimbursement Amount:</Typography>
                <Typography justifyContent="flex-end" variant={fontVariant}>
                    {`${formatCurrency(majorMedicalBenefitCost?.reimbursementAmount as number, {
                        preserveDecimal: true,
                    })} per month`}
                </Typography>
            </Grid>
            <Grid width="100%">
                <Divider
                    sx={{
                        borderColor: 'black',
                        color: 'black',
                        height: '3px',
                        my: 1,
                    }}
                />
            </Grid>
            <Grid container direction="row" justifyContent="space-between" width="100%">
                <Typography fontWeight="bold" marginLeft={1.3} variant={fontVariant}>
                    Your Major Medical Cost:
                </Typography>
                <Typography
                    color="secondary"
                    fontWeight="bold"
                    justifyContent="flex-end"
                    variant={fontVariant}
                >
                    {`${formatCurrency(formattedYourCost, { preserveDecimal: true })} per month`}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default hot(module)(MajorMedicalCostBreakdown);
