import { Button, Divider, Grid, Typography } from '@mui/material';
import { getLatestYearForRates } from 'actions/marketplacePlan/getLatestYearForRates';
import {
    GET_SELECTED_PLANS_FOR_RENEWAL_ACTION,
    getSelectedPlansForRenewal,
} from 'actions/selectedPlan/getSelectedPlansForRenewal';
import { completeTask } from 'actions/taskFlows/completeTask';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { EnrollmentStatuses } from 'api/generated/enums';
import { ISelectedPlansDto, TaskDto } from 'api/generated/models';
import ConditionalTooltip from 'components/ConditionalTooltip';
import Skeleton from 'components/Skeleton';
import HelpContact from 'components/helpComponents/HelpContact';
import MajorMedicalBenefitCost from 'components/majorMedicalBenefitCostBreakdownModal/MajorMedicalBenefitCost';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { some } from 'lodash';
import {
    MAX_WIDTH,
    convertToPlanInfo,
    ichraFlowSkeletonRowProps,
} from 'pages/dashboard/ichraTaskFlow/ichraFlowUtilities';
import KeepOrChangeBenefitCard from 'pages/dashboard/taskLists/member/tasks/keepOrChange/KeepOrChangeBenefitCard';
import { ADVISEMENT_SCHEDULED_MESSAGE } from 'pages/shop/ShopHeader';
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { arrayHasValue, hasValue } from 'utilities/index';

const KeepOrChangePage = ({ currentTask }: { currentTask: TaskDto }) => {
    const { isLoading, selectedPlans } = useSelector((state: AppStore) => ({
        isLoading: hasApiActivity(
            state,
            GET_SELECTED_PLANS_FOR_RENEWAL_ACTION,
            LIST_VISIBLE_FLOWS_ACTION
        ),
        selectedPlans: state.selectedPlansForRenewal,
    }));
    const dispatch = useThunkDispatch();
    const { userId, user, yearlyUserInfo } = useUserProps();
    const [chunk, setChunk] = useState<ISelectedPlansDto | undefined>();

    const hasIneligiblePlan = selectedPlans.comparedMarketplaceAndOffExchangeSelectedPlans?.some(
        (plan) => hasValue(plan.ineligibleToKeepReason)
    );
    const shouldDisableKeep = hasIneligiblePlan;

    const ifAnyChanges = some(selectedPlans.comparedMarketplaceAndOffExchangeSelectedPlans, (x) =>
        arrayHasValue(x.comparisons)
    );

    const handleChange = () => {
        // TODO in ticket 21344: pop the advisement modal if that's supposed to happen OR complete the task with no response and move on to next task
        dispatch(completeTask(currentTask.globalId, undefined));
    };

    const handleKeep = () => {
        const forceAdvisement = false;
        if (forceAdvisement) {
            // TODO in ticket 21344: force advisement
        } else {
            dispatch(completeTask(currentTask.globalId, chunk));
        }
    };

    useEffect(() => {
        const planInfos =
            selectedPlans?.comparedMarketplaceAndOffExchangeSelectedPlans?.map((sp) =>
                convertToPlanInfo(
                    sp.householdMembersCovered,
                    sp.isPrimaryCovered,
                    undefined,
                    sp,
                    sp.year
                )
            ) ?? [];
        selectedPlans?.effectiveMajorMedicalSelectedPlans?.forEach((p) => {
            const planInfo = convertToPlanInfo(
                p.householdMembersCovered,
                p.isPrimaryCovered,
                undefined,
                p,
                p.year
            );
            planInfos.push(planInfo);
        });
        setChunk({ isWaived: false, planInfoDtos: planInfos } as ISelectedPlansDto);
    }, [selectedPlans]);

    useEffect(() => {
        const year = user?.activeDate?.getYear();
        if (hasValue(year)) {
            dispatch(getSelectedPlansForRenewal(userId, year));
        }
    }, [dispatch, user?.activeDate, userId]);

    useEffect(() => {
        dispatch(getLatestYearForRates());
    }, [dispatch]);

    return (
        <Grid
            alignItems="center"
            container
            direction="column"
            item
            maxWidth={MAX_WIDTH}
            rowGap={2}
            width="100%"
        >
            <Grid item justifyContent="center" marginBottom={2} xs={12}>
                <Typography textAlign="center" variant="h3">
                    Keep or Change Benefits
                </Typography>

                <Typography textAlign="center" variant="body2">
                    Do you want to keep the plan(s) you are on now for next year?{' '}
                    {ifAnyChanges &&
                        `Here's
                        how your plans will change for ${user?.activeDate?.getYear()}`}
                </Typography>
            </Grid>
            <Grid item width="100%">
                <Skeleton
                    isEnabled={isLoading}
                    rowProps={ichraFlowSkeletonRowProps}
                    structure={[
                        [
                            {
                                columnProps: { flex: 1 },
                                count: 1,
                                height: 290,
                                sx: { mb: 3 },
                                width: '100%',
                            },
                        ],
                        [
                            {
                                columnProps: { flex: 1 },
                                count: 1,
                                height: 80,
                                sx: { mb: 3 },
                                width: '100%',
                            },
                        ],
                        [
                            {
                                columnProps: { alignItems: 'center' },
                                count: 1,
                                height: 45,
                                sx: { mb: 3 },
                                width: 150,
                            },
                            {
                                columnProps: { alignItems: 'center' },
                                count: 1,
                                height: 45,
                                sx: { mb: 3 },
                                width: 150,
                            },
                        ],
                    ]}
                >
                    {selectedPlans?.comparedMarketplaceAndOffExchangeSelectedPlans?.map((sp) => (
                        <Grid item key={`${sp.planId}-${sp.planTypeId}`} xs={12}>
                            <KeepOrChangeBenefitCard selectedPlan={sp} />
                        </Grid>
                    ))}
                    {selectedPlans?.effectiveMajorMedicalSelectedPlans?.map((sp) => (
                        <Grid item key={`${sp.planId}-${sp.planTypeId}`} xs={12}>
                            <KeepOrChangeBenefitCard selectedPlan={sp} />
                        </Grid>
                    ))}

                    {!hasIneligiblePlan && (
                        <Grid container item justifyContent="center" xs={12}>
                            <MajorMedicalBenefitCost
                                costText="Your Major Medical Cost"
                                majorMedicalBenefitCost={selectedPlans?.majorMedicalBenefitCost}
                            />
                        </Grid>
                    )}
                    <Grid item marginTop={2} xs={12}>
                        <Divider />
                    </Grid>
                    <Grid container gap={3} item justifyContent="center" marginTop={2} xs={12}>
                        <ConditionalTooltip
                            isDisabled={!shouldDisableKeep}
                            title="You are ineligible for one or more plans due to household changes or plans leaving the market."
                        >
                            <Button
                                disabled={shouldDisableKeep}
                                onClick={handleKeep}
                                variant="outlined"
                            >
                                Keep
                            </Button>
                        </ConditionalTooltip>
                        <Button onClick={handleChange} variant="outlined">
                            Change
                        </Button>
                    </Grid>
                    <Grid item marginTop={3} xs={12}>
                        {yearlyUserInfo?.enrollmentStatus?.value ===
                        EnrollmentStatuses.AdvisementScheduled ? (
                            <Typography textAlign="center" variant="body2">
                                {ADVISEMENT_SCHEDULED_MESSAGE}
                            </Typography>
                        ) : (
                            <HelpContact isIchraFlow />
                        )}
                    </Grid>
                </Skeleton>
            </Grid>
        </Grid>
    );
};

export default hot(module)(KeepOrChangePage);
