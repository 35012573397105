import { SuccessEvent, useFinchConnect } from '@tryfinch/react-connect';
import {
    CREATE_FINCH_ACCESS_TOKEN_ACTION,
    createFinchAccessToken,
} from 'actions/finch/createFinchAccessToken';
import { FinchIntegrationStatuses } from 'api/generated/enums';
import { GenerateHrsIntegrationKey } from 'api/generated/permissions';
import Button from 'components/Button';
import ProfileAttribute from 'components/ProfileAttribute';
import Toast from 'components/Toast';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import startCase from 'lodash/startCase';
import React from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { hasSomePermissions } from 'selectors/index';
import { FINCH_CLIENT_ID } from 'utilities/finch';

const FinchIntegrationSection = () => {
    const dispatch = useThunkDispatch();
    const { canConnectToFinch, isSaving } = useSelector((state: AppStore) => ({
        canConnectToFinch: hasSomePermissions(state, GenerateHrsIntegrationKey),
        isSaving: hasApiActivity(state, CREATE_FINCH_ACCESS_TOKEN_ACTION),
    }));
    const { team } = useTeamProps();

    const { open } = useFinchConnect({
        clientId: FINCH_CLIENT_ID,
        onError: () => Toast.error('Failed to connect with Finch'),
        onSuccess: (value: SuccessEvent) => {
            if (team) {
                const code = value.code;
                const teamId = team?.teamId;
                dispatch(createFinchAccessToken(code, teamId));
            }
        },
        products: ['company', 'directory', 'individual', 'employment', 'payment', 'pay_statement'],
        sandbox: 'finch',
        zIndex: 2000,
    });

    return (
        <React.Fragment>
            {canConnectToFinch &&
                team?.finchIntegrationStatus !== FinchIntegrationStatuses.Connected && (
                    <ProfileAttribute label="Connect to Finch">
                        <Button
                            isLoading={isSaving}
                            onClick={() => open()}
                            size="small"
                            variant="outlined"
                        >
                            Connect
                        </Button>
                    </ProfileAttribute>
                )}
            {team?.finchIntegrationStatus !== FinchIntegrationStatuses.NotConfigured && (
                <React.Fragment>
                    <ProfileAttribute label="Status">
                        {startCase(
                            FinchIntegrationStatuses[
                                team?.finchIntegrationStatus ??
                                    FinchIntegrationStatuses.NotConfigured
                            ]
                        )}
                    </ProfileAttribute>
                    {team?.finchProviderId && (
                        <ProfileAttribute label="Provider">
                            {startCase(team?.finchProviderId)}
                        </ProfileAttribute>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default hot(module)(FinchIntegrationSection);
